// src/components/jb-home-page-hero/jb-home-page-hero.smooth-scroll.ts
function scrollTo(pos) {
  const speed = 500;
  const scrollStartY = window.scrollY || document.documentElement.scrollTop || 0;
  let currentTimestamp = 0;
  const scrollDistanceInPx = Math.abs(scrollStartY - pos);
  const scrollDistance = pos - scrollStartY;
  const suggestedAnimationDuration = scrollDistanceInPx / speed;
  const animationDuration = Math.max(0.1, Math.min(suggestedAnimationDuration, 0.8));
  function easeOutSine(t) {
    return Math.sin(t * (Math.PI / 2));
  }
  function scroll(x, y) {
    window.scrollTo(x, y);
  }
  function tick() {
    const timeIncrement = 1 / 60;
    currentTimestamp += timeIncrement;
    const time = currentTimestamp / animationDuration;
    const position = easeOutSine(time);
    if (time < 1) {
      window.requestAnimationFrame(tick);
      const x = 0;
      const scrollIncrement = scrollDistance * position;
      const nextYPosition = scrollStartY + scrollIncrement;
      scroll(x, nextYPosition);
    }
  }
  window.requestAnimationFrame(tick);
}

export {
  scrollTo
};
