import {
  jbLinkClickedEventToJbTrackingEvent
} from "./chunk.OF3GOBXN.js";

// src/components/jb-home-page-hero/jb-home-page-hero.tracking.ts
function tracking1FirstDisplay(el, current) {
  const currentIndex = current ?? el.currentItemIndex;
  const eventDetails = {
    event: "ce_display",
    event_version: "v2",
    component_name: "image-hero",
    component_topic: el.items[currentIndex].title,
    topic_position: currentIndex + 1,
    interaction_effect: "navigation",
    interaction_element: "Internal Links",
    interaction_result: window.location.href,
    interaction_type: "click"
  };
  el.dispatchEvent(
    new CustomEvent("jb-tracking", {
      detail: eventDetails,
      bubbles: true,
      composed: true
    })
  );
}
function tracking2DiscoverMore(el, e) {
  const jbTrackingEvent = jbLinkClickedEventToJbTrackingEvent(e, {
    component_name: "image-hero",
    component_topic: el.items[el.currentItemIndex].title,
    topic_position: el.currentItemIndex + 1
  });
  if (jbTrackingEvent) {
    el.dispatchEvent(jbTrackingEvent);
  }
}
function tracking3Navigation(el, current, target, type) {
  const eventDetails = {
    event: "ce_interaction",
    event_version: "v2",
    component_name: "image-hero",
    component_topic: el.items[current]?.title,
    topic_position: current + 1,
    target_topic: el.items[target]?.title,
    target_position: target + 1,
    interaction_effect: "navigation",
    interaction_element: "component",
    interaction_result: "change topic",
    interaction_type: type
  };
  el.dispatchEvent(
    new CustomEvent("jb-tracking", {
      detail: eventDetails,
      bubbles: true,
      composed: true
    })
  );
}
function tracking4OurSolutions(el) {
  const eventDetails = {
    event: "ce_interaction",
    event_version: "v2",
    component_name: "image-hero",
    component_topic: el.items[el.currentItemIndex].title,
    topic_position: el.currentItemIndex + 1,
    interaction_effect: "navigation",
    interaction_element: "Internal Links",
    interaction_result: window.location.href,
    interaction_type: "click"
  };
  el.dispatchEvent(
    new CustomEvent("jb-tracking", {
      detail: eventDetails,
      bubbles: true,
      composed: true
    })
  );
}

export {
  tracking1FirstDisplay,
  tracking2DiscoverMore,
  tracking3Navigation,
  tracking4OurSolutions
};
